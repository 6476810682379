// i18next-extract-mark-ns-start mollie-alternative

import {AnchorLink} from 'components/AnchorLink';
import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {IndexBackground} from 'components/landings/IndexBackground';
import {IndexImage} from 'components/landings/IndexImage';
import {List} from 'components/List';
import {
  ListRow,
  MobilePlanLabel,
  PlansContainer,
  PricingSection
} from 'components/pricing/PricingSection';
import {Section, SectionActions, SectionHeader} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';
import logo from 'images/mollie.svg';
import selling from 'images/selling.svg';
import React from 'react';
import {HiCheck, HiOutlineX} from 'react-icons/all';
import styled from 'styled-components';
import {InternalPageLink} from 'components/links/Pages';

const IndexContent = styled.div`
  padding-top: 100px;
  padding-bottom: 90px;
  max-width: 515px;
`;

const PlanHeader = styled.div`
  padding: 60px 20px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 180px;
`;

export const PlansTable = styled.div`
  position: relative;
  padding: 0 20px 0 50%;

  @media (max-width: 1024px) {
    padding: 0;
  }
`;

const Plan = styled.div`
  margin: -30px 10px 50px 10px;
  position: relative;
  z-index: 1;
  width: 250px;
  flex-shrink: 0;
  box-shadow: ${(props) => props.theme.boxShadow};
  background: #ffffff;
  border-radius: ${(props) => props.theme.borderRadius};

  @media (max-width: 768px) {
    width: 100%;
    margin: 50px 0 0;
  }
`;

const PricingContent = styled(Content)`
  padding-bottom: 100px;
`;

const PlanLabels = styled.ul`
  padding: 0;
  position: absolute;
  top: 150px;
  margin: 0;
  left: 0;
  list-style: none;
  width: 100%;

  li:after {
    display: block;
    content: '';
    width: 75%;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

const PlanFeatures = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;

  ${ListRow} {
    justify-content: center;
  }
`;

export const PlansList = styled.div`
  display: flex;
  justify-content: space-around;
  @media (max-width: 1024px) {
    justify-content: initial;
    overflow-x: scroll;
  }
`;

const YesIcon = styled(HiCheck)`
  font-size: 32px;
  color: green;
`;

const NoIcon = styled(HiOutlineX)`
  font-size: 32px;
  color: red;
`;

const Page: React.FC = () => {
  const {t} = useI18next();
  return (
    <>
      <SEO
        path="mollie-alternative"
        title={t('Mollie Alternative')}
        description={t(
          'Looking for the best alternative to Mollie? Accept all major payment methods in a single platform with MONEI’s payment gateway. Join now ››'
        )}
      />
      <IndexBackground sx={{left: {xl: '50%'}, height: {xl: '1000px'}, width: {xl: '2000px'}}}>
        <Content>
          <IndexContent>
            <SectionHeader underline tagName="h1">
              <Trans>Mollie Alternative</Trans>
            </SectionHeader>
            <Trans parent="p">
              Are you looking for an alternative to Mollie to integrate online payments into your
              e-commerce site?
            </Trans>
            <Trans parent="p">
              Meet MONEI, the advanced e-commerce payment gateway that helps you grow your business
              faster. Sign up to accept and manage all major and alternative payment methods in a
              single platform. This way, you can reach more people, improve customer satisfaction,
              and make more sales.
            </Trans>
            <Trans parent="p">
              You'll also save money that you can reinvest to grow your store. We're the only
              payment service provider that offers dynamic billing — as you sell more, your
              transaction fees will decrease.
            </Trans>
            <SectionActions>
              <SignUpButton variant="light" style={{marginRight: 15}}>
                <Trans>Open an Account</Trans>
              </SignUpButton>
              <ContactSalesButton />
            </SectionActions>
            <IndexImage
              top="150px"
              width="700px"
              src={selling}
              alt="PayPal Alternative"
              title="PayPal Alternative"
              className="hide-on-mobile"
            />
          </IndexContent>
        </Content>
      </IndexBackground>
      <Background>
        <PricingContent>
          <Section centered>
            <div style={{maxWidth: 800}}>
              <SectionHeader>
                <Trans>The benefits of choosing MONEI over Mollie</Trans>
              </SectionHeader>
              <Trans parent="p">
                We presented our architecture and digital payments solution at{' '}
                <AnchorLink
                  href="https://aws.amazon.com/es/blogs/startups/serverless-architecture-powers-moneis-fast-secure-and-scalable-digital-payment-solutions/"
                  target="_blank"
                  title="Winner of the AWS’ Startup Architecture Challenge">
                  Amazon’s AWS Startup Architecture of the Year Program (Iberia region)
                </AnchorLink>{' '}
                — and we won! User satisfaction, reliability, and security are key in online
                payments. That's why we strive to innovate payment technology so you can offer your
                customers the widest range of{' '}
                <InternalPageLink slug="payment-methods">payment methods</InternalPageLink>.
              </Trans>
              <Trans parent="p">
                There are several advantages to choosing MONEI over Mollie as your payment gateway
                alternative. Here are a few and check out the table below for a complete list of
                features we offer that Mollie doesn’t.
              </Trans>
              <List>
                <Trans parent="li">
                  Accept the widest range of{' '}
                  <InternalPageLink slug="payment-methods">payment methods</InternalPageLink>{' '}
                  including: all major{' '}
                  <InternalPageLink slug="payment-methods/credit-cards">
                    credit cards
                  </InternalPageLink>
                  , digital wallets (
                  <InternalPageLink slug="payment-methods/apple-pay">Apple Pay</InternalPageLink>,{' '}
                  <InternalPageLink slug="payment-methods/google-pay">Google Pay</InternalPageLink>,
                  and <InternalPageLink slug="payment-methods/paypal">PayPal</InternalPageLink>),
                  and local payment methods such as{' '}
                  <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink> in Spain.
                </Trans>
                <Trans parent="li">
                  Enjoy 1-day payment settlements once you become a verified merchant.
                </Trans>
                <Trans parent="li">Customize the design of your checkout page.</Trans>
                <Trans parent="li">
                  Benefit from lower transaction fees — you’ll save more money to reinvest and grow
                  your business.
                </Trans>
                <Trans parent="li">
                  Use <InternalPageLink slug="features/pay-by-link">Pay By Link</InternalPageLink>{' '}
                  to send your customers a unique link to pay online in one click.
                </Trans>
                <Trans parent="li">Get free and multilingual onboarding and support.</Trans>
              </List>
              <Trans parent="p">
                Just like Mollie, connecting your e-commerce store with MONEI is quick and easy.
                We’re a <InternalPageLink slug="shopify-payment-gateway">Shopify</InternalPageLink>{' '}
                partner and also connect with other platforms such as{' '}
                <InternalPageLink slug="wix-payment-gateway">Wix</InternalPageLink> and{' '}
                <InternalPageLink slug="woocommerce-payment-gateway">WooCommerce</InternalPageLink>.
              </Trans>
              <Trans parent="p">
                Choose the{' '}
                <AnchorLink href="https://docs.monei.com/docs/">
                  payment gateway integration
                </AnchorLink>{' '}
                that works best for your business. Enjoy responsive designs that will help improve
                conversions.{' '}
                <AnchorLink href="https://support.monei.com/hc/en-us/categories/360003383258-Customization">
                  Customize the appearance
                </AnchorLink>{' '}
                of your mobile-ready checkout page with your logo and brand colors. And add your
                custom URL to build trust and brand awareness — this way, shoppers will stay on your
                website during the entire payment flow.
              </Trans>
              <Trans parent="p">
                Use our <InternalPageLink slug="shopify-payment-gateway">API</InternalPageLink> to
                seamlessly integrate MONEI with your e-commerce platform — you'll be ready to sell
                in minutes!
              </Trans>
              <div style={{textAlign: 'center', marginBottom: 50}}>
                <SignUpButton variant="dark">
                  <Trans>Open an Account</Trans>
                </SignUpButton>
              </div>
            </div>
          </Section>
          {/* i18next-extract-mark-ns-stop mollie-alternative */}
          {/* i18next-extract-mark-ns-start alternatives */}
          <PricingSection>
            <PlansTable>
              <PlanLabels>
                <Trans parent={ListRow}>3D Secure transactions</Trans>
                <Trans parent={ListRow}>1-day payment settlement</Trans>
                <Trans parent={ListRow}>User-friendly APIs</Trans>
                <Trans parent={ListRow}>Mobile payments</Trans>
                <Trans parent={ListRow}>Hosted service</Trans>
                <Trans parent={ListRow}>Google Pay</Trans>
                <Trans parent={ListRow}>Apple Pay</Trans>
                <Trans parent={ListRow}>PayPal</Trans>
                <Trans parent={ListRow}>Recurring payments</Trans>
                <Trans parent={ListRow}>PCI DSS Level 1</Trans>
                <Trans parent={ListRow}>Free and multilingual onboarding and support</Trans>
                <Trans parent={ListRow}>
                  Integration with popular e-commerce platforms (Shopify, Wix, WooCommerce...)
                </Trans>
                <Trans parent={ListRow}>Multilingual dashboard</Trans>{' '}
                <Trans parent={ListRow}>Real-time control panel with advanced analytics</Trans>
                <Trans parent={ListRow}>
                  Client database with location and device info for each payment
                </Trans>
                <Trans parent={ListRow}>Advanced payments filters (payments and clients)</Trans>
                <Trans parent={ListRow}>
                  Multi-user capabilities with different access levels (admin or read-only)
                </Trans>
                <Trans parent={ListRow}>
                  All historical transaction data available (not only 12 months)
                </Trans>
                <Trans parent={ListRow}>Pay By Link (send a payment link via email or SMS)</Trans>
                <Trans parent={ListRow}>Process refunds from the control panel</Trans>
                <Trans parent={ListRow}>
                  Customize the appearance of the payment screen (logo, brand colors...)
                </Trans>
                <Trans parent={ListRow}>
                  Payment frame in your custom domain (the user always sees mysite.com)
                </Trans>
                <Trans parent={ListRow}>
                  On-site payments (no redirects), including Dynamic 3DS to improve conversion rates
                </Trans>
                <Trans parent={ListRow}>Real-time credit card info formatting</Trans>
                <Trans parent={ListRow}>
                  Automatic translation of placeholders to the customer's default language
                </Trans>
                <Trans parent={ListRow}>
                  Responsive design to adapt modules to the customer's screen
                </Trans>{' '}
                <Trans parent={ListRow}>
                  Customizable payment screen to offer a 100% integrated experience
                </Trans>
                <Trans parent={ListRow}>REST API connection for all kinds of integrations</Trans>
                <Trans parent={ListRow}>Payment methods tokenization</Trans>
                <Trans parent={ListRow}>
                  Webhooks to visualize all your clients/payments info from multiple sources (ERP,
                  CRM...)
                </Trans>
                <Trans parent={ListRow}>GraphQL API for data consumption</Trans>
                <Trans parent={ListRow}>
                  SMS and email notifications to clients for receipts, orders, and invoices
                </Trans>
                <Trans parent={ListRow}>
                  Smart routing engine sends payments sent to multiple acquirers to approve more
                  payments
                </Trans>
                <Trans parent={ListRow}>Full Direct Debit SEPA integration</Trans>
                <Trans parent={ListRow}>
                  Full white label solution for financial institutions and banks
                </Trans>
                <Trans parent={ListRow}>Combined acquiring and payment gateway solution</Trans>
                <Trans parent={ListRow}>
                  Transaction processing with new local payment methods like Bizum
                </Trans>
                <Trans parent={ListRow}>Installment payments</Trans>
                <Trans parent={ListRow}>
                  Dynamic billing — as you sell more, your transaction fees decrease
                </Trans>
                <Trans parent={ListRow}>Ready to use React components</Trans>
              </PlanLabels>
              <PlansContainer>
                <PlansList>
                  <Plan>
                    <PlanHeader>
                      <img src="https://assets.monei.com/images/logo.svg" alt="MONEI" width={180} />
                    </PlanHeader>
                    <PlanFeatures>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>3D Secure transactions</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>1-day payment settlement</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>User-friendly APIs</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Mobile payments</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Hosted service</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Google Pay</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Apple Pay</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>PayPal</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Recurring payments</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>PCI DSS Level 1</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Free and multilingual onboarding and support
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Integration with popular e-commerce platforms (Shopify, Wix,
                          WooCommerce...)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Multilingual dashboard</Trans>
                        <YesIcon />
                      </ListRow>{' '}
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Real-time control panel with advanced analytics
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Client database with location and device info for each payment
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Advanced payments filters (payments and clients)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Multi-user capabilities with different access levels (admin or read-only)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          All historical transaction data available (not only 12 months)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Pay By Link (send a payment link via email or SMS)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Process refunds from the control panel
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Customize the appearance of the payment screen (logo, brand colors...)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Payment frame in your custom domain (the user always sees mysite.com)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          On-site payments (no redirects), including Dynamic 3DS to improve
                          conversion rates
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Real-time credit card info formatting
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Automatic translation of placeholders to the customer's default language
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Responsive design to adapt modules to the customer's screen
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Customizable payment screen to offer a 100% integrated experience
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          REST API connection for all kinds of integrations
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Payment methods tokenization</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Webhooks to visualize all your clients/payments info from multiple sources
                          (ERP, CRM...)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>GraphQL API for data consumption</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          SMS and email notifications to clients for receipts, orders, and invoices
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Smart routing engine sends payments sent to multiple acquirers to approve
                          more payments
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Full Direct Debit SEPA integration</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Full white label solution for financial institutions and banks
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Combined acquiring and payment gateway solution
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Transaction processing with new local payment methods like Bizum
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Installment payments</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Dynamic billing — as you sell more, your transaction fees decrease
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Ready to use React components</Trans>
                        <YesIcon />
                      </ListRow>
                    </PlanFeatures>
                  </Plan>
                  <Plan>
                    <PlanHeader>
                      <img src={logo} alt="Mollie" width={150} />
                    </PlanHeader>
                    <PlanFeatures>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>3D Secure transactions</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>1-day payment settlement</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>User-friendly APIs</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Mobile payments</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Hosted service</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Google Pay</Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Apple Pay</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>PayPal</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Recurring payments</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>PCI DSS Level 1</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Free and multilingual onboarding and support
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Integration with popular e-commerce platforms (Shopify, Wix,
                          WooCommerce...)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Multilingual dashboard</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Real-time control panel with advanced analytics
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Client database with location and device info for each payment
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Advanced payments filters (payments and clients)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Multi-user capabilities with different access levels (admin or read-only)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          All historical transaction data available (not only 12 months)
                        </Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Pay By Link (send a payment link via email or SMS)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Process refunds from the control panel
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Customize the appearance of the payment screen (logo, brand colors...)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Payment frame in your custom domain (the user always sees mysite.com)
                        </Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          On-site payments (no redirects), including Dynamic 3DS to improve
                          conversion rates
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Real-time credit card info formatting
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Automatic translation of placeholders to the customer's default language
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Responsive design to adapt modules to the customer's screen
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Customizable payment screen to offer a 100% integrated experience
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          REST API connection for all kinds of integrations
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Payment methods tokenization</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Webhooks to visualize all your clients/payments info from multiple sources
                          (ERP, CRM...)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>GraphQL API for data consumption</Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          SMS and email notifications to clients for receipts, orders, and invoices
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Smart routing engine sends payments sent to multiple acquirers to approve
                          more payments
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Full Direct Debit SEPA integration</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Full white label solution for financial institutions and banks
                        </Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Combined acquiring and payment gateway solution
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Transaction processing with new local payment methods like Bizum
                        </Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Dynamic billing — as you sell more, your transaction fees decrease
                        </Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Ready to use React components</Trans>
                        <YesIcon />
                      </ListRow>
                    </PlanFeatures>
                  </Plan>
                </PlansList>
              </PlansContainer>
            </PlansTable>
          </PricingSection>
        </PricingContent>
      </Background>
    </>
  );
};

export default Page;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: {in: ["common", "mollie-alternative", "alternatives"]}
        language: {eq: $language}
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
